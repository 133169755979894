import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const requireAuth = () => (to, from, next) => {
  let accesstoken = localStorage.getItem("accesstoken");
  if (accesstoken == null || accesstoken == undefined) {
    next("/");
  } else {
    next();
  }
  next();
};

const redirect = () => (to, from, next) => {
  let accesstoken = localStorage.getItem("accesstoken");
  if (accesstoken) {
    next("/home");
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/account/Login.vue"),
    beforeEnter: redirect(),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/network",
    name: "network",
    component: () => import("../views/Network.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/equipment",
    name: "equipment",
    component: () => import("../views/Equipment.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/smartsensor",
    name: "smartsensor",
    component: () => import("../views/SmartSensor.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/localsensor",
    name: "localsensor",
    component: () => import("../views/LocalSensor.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/alarm",
    name: "alarm",
    component: () => import("../views/Alarm.vue"),
    beforeEnter: requireAuth(),
  },
  {
    path: "/policy",
    name: "policy",
    component: () => import("../views/Policy.vue"),
    beforeEnter: requireAuth(),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { x: 0, y: 0 };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
