import axios from "axios";
import { baseUrl, headers } from "../config/env";

// /hello
export function hello() {
  return axios.get(baseUrl + `/hello`, { headers });
}
// login
export function login(data) {
  return axios.post(baseUrl + `/accounts/login`, data, { headers });
}
// login
export function logout(data) {
  return axios.put(baseUrl + `/accounts/logout`, data, { headers });
}
// userInfo
export function fetchProfile() {
  return axios.get(baseUrl + `/accounts/profile`, { headers });
}
// userInfo
export function fetchUserInfo(id) {
  return axios.get(baseUrl + `/accounts/userInfo/${id}`, { headers });
}
// userList
export function fetchUserList(params) {
  return axios.get(baseUrl + `/accounts/userList`, { params, headers });
}
// join
export function createUser(data) {
  return axios.post(baseUrl + `/accounts/join`, data, { headers });
}
// update user
export function updateUser(data) {
  return axios.put(baseUrl + `/accounts/update`, data, { headers });
}
// delete user
export function deleteUser(id) {
  return axios.delete(baseUrl + `/accounts/delete/${id}`, { headers });
}

// create api
export function createApi(data) {
  return axios.post(baseUrl + `/monitor/api/create`, data, { headers });
}
// apiList
export function fetchApiList(params) {
  return axios.get(baseUrl + `/monitor/api/list`, { params, headers });
}
// delete api
export function deleteApi(id) {
  return axios.delete(baseUrl + `/monitor/api/delete/${id}`, { headers });
}
// create equipment
export function createEquipment(data) {
  return axios.post(baseUrl + `/monitor/equipment/create`, data, { headers });
}
// update equipment
export function updateEquipment(data) {
  return axios.put(baseUrl + `/monitor/equipment/update`, data, { headers });
}
// equipment List
export function fetchEquipmentList(params) {
  return axios.get(baseUrl + `/monitor/equipment/list`, { params, headers });
}
// equipment List noPage
export function fetchEquipmentListAll(params) {
  return axios.get(baseUrl + `/monitor/equipment/list/all`, { params, headers });
}
// equipment info
export function fetchEquipmentInfo(id) {
  return axios.get(baseUrl + `/monitor/equipment/info/${id}`, { headers });
}
// equipment alarm
export function fetchEquipmentAlarm(id) {
  return axios.get(baseUrl + `/monitor/equipment/alarm/${id}`, { headers });
}
// sensor alarm
export function fetchSensorAlarm(params) {
  return axios.get(baseUrl + `/monitor/equipment/sensor`, { params, headers });
}
// equipment alarm
export function fetchEquipmentData(params) {
  headers.authorization = localStorage.getItem("accesstoken");
  return axios.get(baseUrl + `/monitor/equipment/api`, { params, headers });
}
// equipment alarm
export function createAlarm(data) {
  return axios.post(baseUrl + `/auth/alarm`, data, { headers });
}
// delete equipment
export function deleteEquipment(id) {
  return axios.delete(baseUrl + `/monitor/equipment/delete/${id}`, { headers });
}
export function fetchNetworkStatus(params) {
  return axios.get(baseUrl + `/monitor/equipment/networkstatus`, {params, headers});
}
