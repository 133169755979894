<template>
  <div class="lnb">
    <div class="lnbLogo">
      <router-link to="/">
        <img src="@/assets/images/logo.png" alt="logo" />
      </router-link>
    </div>

    <el-row class="tac mainMenu">
      <el-col :span="12">
        <el-menu class="el-menu-vertical-demo" :default-active="navbarState">
          <el-menu-item index="1">
            <router-link to="/">
              <span> 통합 모니터링 시스템 </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="2">
            <router-link to="/equipment">
              <span> 회전 설비 이상감지 </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="3">
            <router-link to="/network">
              <span> 네트워크 트래픽 진단 </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="4">
            <router-link to="/localsensor">
              <span> 실시간 센서 트렌드 </span>
            </router-link>
          </el-menu-item>
          <!-- <el-submenu index="4">
            <template slot="title">
              <span>실시간 센서 트렌드</span>
            </template>
            <el-menu-item index="5"
              ><router-link to="/smartsensor">
                <span>스마트 센서</span>
              </router-link></el-menu-item
            >
            <el-menu-item index="6"
              ><router-link to="/localsensor">
                <span>로컬 센서</span>
              </router-link></el-menu-item
            >
          </el-submenu> -->
          <el-menu-item index="5">
            <router-link to="/alarm">
              <span> 이상감지 알림 조회 </span>
            </router-link>
          </el-menu-item>
          <!-- <el-menu-item index="6">
            <router-link to @click.native="logout">
              <span> 로그아웃 </span>
            </router-link>
          </el-menu-item> -->
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { logout } from "@/api/index";
export default {
  data() {
    return {
      isActive: false,
      isDropped: false,
    };
  },
  computed: {
    ...mapState(["navbarState"]),
  },
  mounted() {
  },
  methods: {
    async logout() {
      if (confirm("로그아웃 하시겠습니까?") == false) {
        return;
      }
      let accesstoken = localStorage.getItem("accesstoken");
      if (accesstoken) {
        await logout();
      }
      this.$store.dispatch("LOGOUT");
    },
  },
};
</script>
