<template>
  <div>
    <Lnb v-if="lnbVisible" />
    <GNB v-if="gnbVisible" />
    <router-view />
  </div>
</template>
<script>
import {
  fetchEquipmentData,
  createAlarm,
} from "@/api/index";
import { mapState } from "vuex";
import Lnb from "@/components/Lnb";
import GNB from "@/components/GNB";
export default {
  components: { Lnb, GNB },
  computed: {
    ...mapState(["lnbVisible", "gnbVisible", "network", "sensor", "equipment"]),
  },
  created() {
    // this.getData();
    setTimeout(this.getData, 1000);
    this.loading = setInterval(this.getData, 1000 * 20);
  },
  destroyed() {
    clearInterval(this.loading);
  },
  methods: {
    /**
     * 시간별 데이터 가져오기 기능
     */
    getData() {
      let equipId = localStorage.getItem("equipId");
      let equipObj = this.equipment.find((item) => item.equipmentId == equipId);
      let params = { networkApi: "", sensorApi: "", equipId: "" };
      if (equipObj && equipObj.networkApi != "") {
        params.networkApi = equipObj.networkApiIp;
        params.sensorApi = equipObj.sensorApiIp;
        params.equipId = equipId;
        fetchEquipmentData(params).then((res) => {
          if (res.data.status == 200) {
            let sensor = res.data.data[1][res.data.data[1].length - 1];
            if (sensor != undefined && sensor.anomaly != 0) {
              sensor.equipId = equipId;
              if (equipObj.alarm[0] == true) {
                this.$notify({
                  title: `설비 ID ${equipId} 이상`,
                  message: `${sensor.Time} \nANOMALY: ${sensor.anomaly}`,
                  type: "error",
                  duration: 0,
                });
              }
              /**
               * 알람 생성 기능
               */
              createAlarm(sensor).then((res) => {
                if (res.data.status != 200) {
                  console.log("createAlarm-res-error", res.data.message);
                }
              });
            }
            this.$store.dispatch("SET_EQUIPMENT_DATA", res.data.data);
          } else if (res.data.status == 405) {
            this.$store.dispatch("LOGOUT");
            // this.$store.dispatch("SET_EQUIPMENT_DATA", ["", ""]);
          } else {
            this.$store.dispatch("SET_EQUIPMENT_DATA", ["", ""]);
          }
        });
      }
    },
  },
};
</script>
