const mutations = {
  SET_NAV_INDEX(state, data) {
    state.navbarState = data;
  },
  LOGIN(state, data) {
    state.isLogin = true;
    localStorage.setItem("accesstoken", data);
  },

  LOGOUT(state) {
    state.isLogin = false;
    state.userId = "";
    state.id = null;
    state._id = null;
    localStorage.removeItem("accesstoken");
  },
  SET_USER_INFO(state, data) {
    if (data.id) {
      state.id = data.id;
    }
    if (data._id) {
      state._id = data._id;
    }
    if (data.userId) {
      state.userId = data.userId;
    }
    if (data.isSuper) {
      state.isSuper = data.isSuper;
    }
  },
  SET_LNB_VISIBLE(state, data) {
    state.lnbVisible = data;
  },
  SET_EQUIPMENT_INFO(state, data) {
    if (data) {
      state.equipment = data;
    }
  },
  SET_EQUIPMENT_DATA(state, data) {
    if (data && data[0] != undefined) {
      state.network = data[0];
    }
    if (data && data[1] != undefined) {
      state.sensor = data[1];
    }
    state.smartSensor = [];
    state.localSensor = [];
    data.map((item, i) => {
      if (i > 1) {
        state.localSensor.push({data: item.data, apiName: item.apiName});
        // if (item.name == "로컬센서") {
        // } else if (item.name == "스마트센서") {
        //   state.smartSensor.push({data: item.data, apiName: item.apiName});
        // }
      }
    });
  },
  SET_GNB_VISIBLE(state, data) {
    state.gnbVisible = data;
  },
};
export default mutations;
