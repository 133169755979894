<template>
  <div>
    <div class="modal3">
      <div class="mHeader">
        <router-link to @click.native="handleMove('/')">
          <img src="@/assets/images/logo.png" alt="로고"
        /></router-link>
        <button @click="close">
          <span class="material-icons-outlined"> close </span>
        </button>
      </div>
      <div class="mBody">
        <el-row class="tac mainMenu">
          <el-col :span="12">
            <el-menu
              class="el-menu-vertical-demo"
              :default-active="navbarState"
            >
              <el-menu-item index="1">
                <router-link to @click.native="handleMove('/')">
                  <span> 통합 모니터링 시스템 </span>
                </router-link>
              </el-menu-item>
              <el-menu-item index="2">
                <router-link to @click.native="handleMove('/equipment')">
                  <span> 회전 설비 이상감지 </span>
                </router-link>
              </el-menu-item>
              <el-menu-item index="3">
                <router-link to @click.native="handleMove('/network')">
                  <span> 네트워크 트래픽 진단 </span>
                </router-link>
              </el-menu-item>
              <el-menu-item index="4">
                <router-link to @click.native="handleMove('/localsensor')">
                  <span> 실시간 센서 트렌드 </span>
                </router-link>
              </el-menu-item>
              <!-- <el-submenu index="4">
                <template slot="title">
                  <span>실시간 센서 트렌드</span>
                </template>
                <el-menu-item index="5"
                  ><router-link to @click.native="handleMove('/smartsensor')">
                    <span>스마트 센서</span>
                  </router-link></el-menu-item
                >
                <el-menu-item index="6"
                  ><router-link to @click.native="handleMove('/localsensor')">
                    <span>로컬 센서</span>
                  </router-link></el-menu-item
                >
              </el-submenu> -->
              <el-menu-item index="5">
                <router-link to @click.native="handleMove('/alarm')">
                  <span> 이상감지 알림 조회 </span>
                </router-link>
              </el-menu-item>
              <!-- <el-menu-item index="6">
            <router-link to @click.native="logout">
              <span> 로그아웃 </span>
            </router-link>
          </el-menu-item> -->
            </el-menu>
          </el-col>
        </el-row>
        <!-- <div class="profile flexB" v-if="isLogin"> -->
      </div>

      <div class="profile">
        <div>
          <p>
            <span>스피랩</span><em>{{ userId }}</em>
          </p>
          <button @click="logout">
            <span class="material-icons-outlined"> logout </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";
import { logout } from "@/api/index";
export default {
  data() {
    return {
      moment: moment,
    };
  },
  computed: {
    ...mapState(["userId"]),
  },
  methods: {
    /**
     * 페이지 라우터 기능
     */
    handleMove(path, category) {
      if (category) {
        this.$router.push({
          name: path + "-main-category",
          params: { category: category },
        });
      } else {
        this.$router.push({
          path: path,
        });
      }
      this.close();
    },
    close() {
      this.$emit("close");
    },
    async logout() {
      if (confirm("로그아웃 하시겠습니까?") == false) {
        return;
      }
      let accesstoken = localStorage.getItem("accesstoken");
      if (accesstoken) {
        await logout();
      }
      this.$store.dispatch("LOGOUT");
    },
  },
};
</script>
