<template>
  <div class="dimbox register user">
    <div class="mHeader">
      <p>제품명 및 버전</p>
    </div>
    <div class="mBody">
      <div class="flexB space">
        <p>제품명</p>
        <p class="black">레이판 시리즈</p>
      </div>
      <div class="flexB space">
        <p>버전</p>
        <p class="black">V1.0</p>
      </div>
      <div class="flexB space">
        <p>구성요소명</p>
        <p class="black">레이판 웹매니저 V1.0</p>
      </div>
      <div class="buttonWrap display">
        <button class="point" @click="close">확인</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
