import { fetchProfile, fetchEquipmentList } from "@/api/index";
const actions = {
  SET_NAV_INDEX({ commit }, data) {
    commit("SET_NAV_INDEX", data);
  },
  LOGIN({ commit }, data) {
    commit("LOGIN", data);
  },
  LOGOUT({ commit }) {
    commit("LOGOUT");
    window.location.href = "/";
  },
  SET_USER_INFO({ commit }, data) {
    commit("SET_USER_INFO", data);
  },
  getAccountInfo({ commit, dispatch }) {
    fetchProfile().then((res) => {
      if (res.data.status == 200 && res.data.data) {
        let data = {
          id: res.data.data.id,
          userId: res.data.data.userId,
          _id: res.data.data._id,
          isSuper: res.data.data.isSuper,
        };
        commit("SET_USER_INFO", data);
      } else {
        dispatch("LOGOUT");
      }
    });
  },
  getEquipmentInfo({ commit, dispatch }) {
    fetchEquipmentList().then((res) => {
      let data = [];
      if (res.data.status == 200 && res.data.data) {
        res.data.data.map((item) => {
          data.push({
            equipmentId: item.equipmentId,
            sensorApiIp: item.sensorApiIp,
            networkApiIp: item.networkApiIp,
            networkStatusApiIp: item.networkStatusApiIp,
            emailList: item.emailList,
            smsList: item.smsList,
            alarm: item.alarm,
          });
        });
        commit("SET_EQUIPMENT_INFO", data);
      } else {
        dispatch("LOGOUT");
      }
    });
  },
  SET_EQUIPMENT_DATA({ commit }, data) {
    commit("SET_EQUIPMENT_DATA", data);
  },
  SET_LNB_VISIBLE({ commit }, data) {
    commit("SET_LNB_VISIBLE", data);
  },
  SET_GNB_VISIBLE({ commit }, data) {
    commit("SET_GNB_VISIBLE", data);
  },
};

export default actions;
