const state = {
  navbarState: "0",
  id: null,
  _id: null,
  userId: null,
  isLogin: false,
  isSuper: false,
  lnbVisible: true,
  gnbVisible: true,
  equipment: [],
  network: [],
  sensor: [],
  smartSensor: [],
  localSensor: [],
};

export default state;
