import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/styles/common.scss";
import "element-ui/lib/theme-chalk/index.css";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/ko";
import { logout } from "@/api/index";
Vue.config.productionTip = false;
Vue.use(ElementUI, { locale });

new Vue({
  router,
  store,
  data() {
    return {
      loading: null,
      isMove: 0,
    };
  },
  beforeCreate() {
    if (this.$store.state.isLogin) {
      this.$store.dispatch("getAccountInfo");
      this.$store.dispatch("getEquipmentInfo");
    }
  },
  created() {
    // 1분단위
    // window 객체의 마우스 움직임 이벤트를 확인
    this.loading = setInterval(this.timerIncrement, 1000);
    window.addEventListener("mousemove", this.mousemove);
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.mousemove);
  },
  destroyed() {
    clearInterval(this.loading);
  },
  methods: {
    // 마우스 움직임 이벤트가 있을 시 타임증가 이벤트 초기화
    mousemove() {
      this.isMove = 0;
    },
    async timerIncrement() {
      this.isMove += 1;
      // 5분 단위
      if (this.isMove > 60 * 5) {
        let accesstoken = localStorage.getItem("accesstoken");
        if (accesstoken) {
          await logout();
        }
        this.$store.dispatch("LOGOUT");
      }
    },
  },
  render: (h) => h(App),
}).$mount("#app");
