// export const baseUrl = "http://13.125.205.96";
export const baseUrl = "https://api.raypann.ai";
// export const baseUrl = "http://localhost:3000";

export let headers = {
  "Content-Type": "application/json;charset=UTF-8",
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "X-Content-Type-Options": "nosniff",
  authorization: localStorage.getItem("accesstoken"),
};
