<template>
  <nav>
    <div class="inner flexB">
      <router-link to="/" class="back">
        <img src="@/assets/images/arrow_left_white.svg" alt="" />
      </router-link>
      <!-- <router-link to @click.native="handleGoBack" class="back">
        <img src="@/assets/images/arrow_left.svg" alt="" />
      </router-link> -->
      <h1>{{ title[navbarState] }}</h1>
      <button class="menuBtn" @click="handleVisible()">
        <span class="material-icons-outlined"> menu </span>
      </button>
    </div>
    <div class="utility">
      <p class="info">
        <span>스피랩</span> <em>{{ userId }}</em>
      </p>
      <button @click="handleInfoModal()">
        <span class="material-icons-outlined"> help_outline </span>
      </button>
      <button @click="logout">
        <span class="material-icons-outlined"> logout </span>
      </button>
    </div>
    <transition name="fade">
      <div class="dim" v-if="modalVisible">
        <InfoModal v-if="infoVisible" @close="close" />
        <SideMenu v-if="visible" @close="close" />
        <div class="dimbg" @click="close"></div>
      </div>
    </transition>
  </nav>
</template>
<script>
import { logout } from "@/api/index";
import { mapState } from "vuex";
import SideMenu from "@/components/pop/SideMenu";
import InfoModal from "@/components/pop/InfoModal";
export default {
  components: { SideMenu, InfoModal },

  data() {
    return {
      visible: false,
      infoVisible: false,
      modalVisible: false,
      title: [
        "",
        "통합 모니터링 시스템",
        "회전 설비 이상감지",
        "네트워크 트래픽 진단",
        "실시간 센서 트렌트",
        "이상감지 알림 조회",
      ],
    };
  },
  computed: {
    ...mapState(["userId", "navbarState"]),
  },
  methods: {
    openModal() {
      this.$emit("openModal");
    },
    handleVisible() {
      this.modalVisible = true;
      this.visible = true;
    },
    handleInfoModal() {
      this.modalVisible = true;
      this.infoVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
      this.visible = false;
      this.infoVisible = false;
    },
    close() {
      this.modalVisible = false;
      this.visible = false;
      this.infoVisible = false;
    },
    async logout() {
      if (confirm("로그아웃 하시겠습니까?") == false) {
        return;
      }
      let accesstoken = localStorage.getItem("accesstoken");
      if (accesstoken) {
        await logout();
      }
      this.$store.dispatch("LOGOUT");
    },
  },
};
</script>
